import PlayerNavigation from "./PlayerNavigation";
import PlayerContent from "./PlayerContent";
import { useEffect, useRef, useState } from "react";
import PlayerHeader from "./PlayerHeader";
import PlayerContentScroll from "./PlayerContentScroll";

///content
import screen1 from "../assets/data/influenza/screen1.json";
import screen2 from "../assets/data/influenza/screen2.json";
import screen3 from "../assets/data/influenza/screen3.json";
import screen4 from "../assets/data/influenza/screen4.json";
import screen5 from "../assets/data/influenza/screen5.json";
import screen6 from "../assets/data/influenza/screen6.json";
import screen7 from "../assets/data/influenza/screen7.json";
import screen8 from "../assets/data/influenza/screen8.json";

import patientEncounters from "../assets/data/influenza/patientencounters.json";

const mapping = [
    {
        id: 'patientencounters1',
        data: patientEncounters
    },
    {
        id: 'screen1',
        data: screen1
    },
    {
        id: 'screen2',
        data: screen2
    },
    {
        id: 'screen3',
        data: screen3
    },
    {
        id: 'screen4',
        data: screen4
    },
    {
        id: 'screen5',
        data: screen5
    },
    {
        id: 'screen6',
        data: screen6
    },
    {
        id: 'screen7',
        data: screen7
    },
    {
        id: 'screen8',
        data: screen8
    }
]

const Player = (props) => {
    const [data, setData] = useState(screen1);
    const [answerSelected, setAnswerSelected] = useState(false);
    const [answerCorrect, setAnswerCorrect] = useState(false);
    const [navigationOpen, setNavigationOpen] = useState(false);
    const [mobileMenuOpen, setmobileMenuOpen] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(false);
    const [modbilescoreOpen, setMobileScoreOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    let random = Math.random().toFixed(4);

    let scorePercentage = parseInt((props.data.overallscore / props.data.totalPoints) * 100);

    const selectedCardRef = useRef(null);
    const fbRef = useRef(null);
    const iFrameRef = useRef(null);

    useEffect(() => {
        //data={props.itemsList[props.currentItem]} )
        // console.log(props.itemsList[props.currentItem].data)
        // console.log(`${props.itemsList[props.currentItem].data}`)
        let dataId = props.itemsList[props.currentItem].data
        let pageData = mapping.filter(f => f.id === dataId)[0].data
        setData(pageData)
        window.scrollTo(0,0)

    },[props.currentItem])

    const selectAsnwer = (selAns) => {
        setSelectedAnswer(selAns);
        setAnswerSelected(true);
    }

    const answerEntered = () => {
        setAnswerSelected(true);

    }

    const checkAnswer = () => {        
        setAnswerCorrect(true);
        setTimeout(() => {
            fbRef.current.scrollIntoView(true);
        }, 200);
    }

    const toggleNavigation = () => {
        setNavigationOpen(!navigationOpen);
        if (!navigationOpen) {
            setShowHeader(true)
        }
    }
    const goToItemFromNav = () => {
        // toggleNavigation()
        props.gotoItem()
        setNavigationOpen(false);
    }

    const nextItem = () => {
        setAnswerSelected(false);
        setAnswerCorrect(false);
        setSelectedAnswer(false);
        props.nextItem();
    }
    const prevItem = () => {
        console.log("go to prev")
        // setAnswerSelected(false);
        // setAnswerCorrect(false);
        // setSelectedAnswer(false);
        props.prevItem(props.currentItem - 1);
    }

    const toggleMobileMenu = () => {
        setmobileMenuOpen(!mobileMenuOpen);
    }
    const toggleMobileScores = () => {
        setMobileScoreOpen(!modbilescoreOpen);
    }


    useEffect(() => {
        setTimeout(() => {
            if (selectedCardRef.current) {                
                document.getElementById('card-selected').scrollIntoView(true);
            }
        }, 300)
        localStorage.setItem('currentItem', props.currentItem);
    });
    // useEffect(() => {
    //     var prevScrollpos = window.pageYOffset;
        
    //     function onScroll() {
    //         var currentScrollPos = window.pageYOffset;
    //         // var st = window.scrollTop;
    //         if (prevScrollpos <= currentScrollPos ){
    //             // downscroll code
    //             // console.log("hide header")
    //             setShowHeader(false)
    //         } else {
    //             // upscroll code
    //             setShowHeader(true)
    //         } // else was horizontal scroll
    //         prevScrollpos = currentScrollPos; // For Mobile or negative scrolling

    //     }
      
    //       window.addEventListener("scroll", onScroll);
    //       return () => window.removeEventListener("scroll", onScroll);
    //     }, []);
   

    return(        
        <>
        <PlayerHeader toggleMobileMenu={toggleMobileMenu} mobileMenuOpen={mobileMenuOpen} 
            show={showHeader}
            data={props.data} closePlayer={props.closePlayer}/>
            
            <main className={`player-main ${showHeader ? "show-header" : "hide-header"}`}>
                <PlayerNavigation 
                    data={props.data} 
                    itemsList={props.itemsList} 
                    currentItem={props.currentItem} 
                    gotoItem={props.gotoItem}
                    itemsCompleted={props.itemsCompleted}
                    totalItems={props.data.totalItems}
                    toggleNavigation={toggleNavigation}
                    navigationOpen={navigationOpen}
                    mobileMenuOpen={mobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                    ref={selectedCardRef}
                />
                {props.scroll ? 
                <PlayerContentScroll 
                    data={props.itemsList}
                    items={props.itemsList} 
                    currentItem={props.currentItem} 
                    nextItem={nextItem} 
                    prevItem={prevItem}
                    answerSelected={answerSelected}
                    selectAsnwer={selectAsnwer}
                    answerCorrect={answerCorrect}
                    checkAnswer={checkAnswer}
                    selectedAnswer={selectedAnswer}
                    // navigationOpen={navigationOpen}
                    answerEntered={answerEntered}
                    currentStep={props.currentStep}
                    totalSteps={props.totalSteps}
                    totalItems={props.data.totalItems}
                    ref={{ref1:fbRef,iFrameRef:iFrameRef}}
                    demo={props.data.demo}
                    id={random}
                    gotoItem={props.gotoItem}
                /> : 
                <PlayerContent 
                    data={data} 
                    //data={props.itemsList[props.currentItem]} 
                    currentItem={props.currentItem} 
                    nextItem={nextItem} 
                    prevItem={prevItem}
                    answerSelected={answerSelected}
                    selectAsnwer={selectAsnwer}
                    answerCorrect={answerCorrect}
                    checkAnswer={checkAnswer}
                    selectedAnswer={selectedAnswer}
                    // navigationOpen={navigationOpen}
                    answerEntered={answerEntered}
                    currentStep={props.currentStep}
                    totalSteps={props.totalSteps}
                    totalItems={props.data.totalItems}
                    ref={{ref1:fbRef,iFrameRef:iFrameRef}}
                    demo={props.data.demo}
                    id={random}
                    
                /> }
            </main>
        </>  
    )
}

export default Player