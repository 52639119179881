import Tippy from "@tippyjs/react";
import AssignmentCard from "../shared/AssignmentCard";
import AssignmentGroup from "../assignmentOverview/AssignmentGroup";
import { forwardRef, useEffect, useRef, useState } from "react";

const PLayerNavigation = forwardRef((props, ref) => {
    let itemCount = -1;
    let scrollPos = props.currentItem;
    /*let completionPercentage = (props.itemsCompleted / props.totalItems) * 100;*/
    const navContainerRef = useRef(null);
    const navRef = useRef(null);
    const [role, setRole] = useState('navigation');
    const [disableButtons, setDisableButtons] = useState(false);

    const handleScroll = () => {
        const navHeight = navRef.current.clientHeight;
        const itemHeight = navHeight / props.totalItems;

        var amtToScroll = itemHeight * scrollPos;

        navContainerRef.current.scrollTop = amtToScroll;
    }
    
    const handleNextBtn = () => {
        scrollPos++;
        handleScroll();
    }

    const handlePrevBtn = () => {
        scrollPos--;
        handleScroll();
    }

    useEffect(() => {
        if(!props.navigationOpen) {
            setRole('toolbar');
        }
    }, [role, setRole, props])

    useEffect(() => {
        const navHeight = navRef.current.clientHeight;
        const availSpace = window.innerHeight - 80 - 100;
        if (navHeight < availSpace) {
            setDisableButtons(true)
        } else {
            setDisableButtons(false)
        }
    },[])

    const findPositionInItemList = (ind) => {
        console.log(props.itemsList)
        console.log(ind)
        let itemIndex = props.itemsList.findIndex(f => f.id === ind);

        return itemIndex
    }

    const findCurrentItemId = () => {
        console.log(props.itemsList)
        return {...props.itemsList[props.currentItem]};
    }

    return(
        <aside className={`${props.navigationOpen ? '' : 'collapsed'} ${props.mobileMenuOpen ? '' : 'closed'}`}>
            <div className="aside-inner">
                <div className="nav-top">
                    <button type="button" className="gr-btn link-btn" id="minimizeBtn" onClick={props.toggleNavigation} aria-label={props.navigationOpen ? "Minimize item list" : "Maximize item list"}>
                        <svg focusable="false" className="icon-18" aria-hidden="true">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#${props.navigationOpen ? 'collapse' : 'expand'}-nav`}></use>
                        </svg>
                        <span>Minimize item list</span>
                    </button>
                    <button type="button" className="gr-btn gr-icon-btn" id="closeBtn" onClick={props.toggleMobileMenu}>
                        <svg focusable="false" className="icon-18" aria-hidden="true">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#close-18`}></use>
                        </svg>
                    </button>
                </div>
                <button className="gr-btn icon-btn-24 scroll-btn" onClick={handlePrevBtn} hidden={disableButtons}>
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#descending-24"></use>
                    </svg>
                </button>
                <nav ref={navContainerRef}>
                    <div ref={navRef}>
                        {props.data.assignmentParts.map((part, i) => {
                            itemCount = itemCount + 1;
                            let currentId = findCurrentItemId().id 

                            if (part.title) {
                                return (
                                    <AssignmentGroup data={part} key={`part${i}`} 
                                        open={true}
                                        gotoItem={props.gotoItem} 
                                        findIndex={findPositionInItemList} 
                                        navigate={false}
                                        grouped={true}
                                        currentId={currentId}
                                        />
                                )
                            } else {
                                 return(
                                <ol className="assignment-group standalone" key={`part${i}`}>
                                    {part.assignmentItems.map((item, j) => {
                                        itemCount = itemCount + 1;
                                        
                                        return( 
                                            <li key={`item${j}`}>
                                                <AssignmentCard 
                                                    data={item} 
                                                    gotoItem={() => {props.gotoItem(findPositionInItemList(item.id))}} 
                                                    itemCount={itemCount}
                                                    currentId={currentId}
                                                />
                                            </li>
                                        )
                                    })}
                                </ol>
                            )
                            }
                            // return(
                                // <ol className="assignment-group" key={`part${i}`}>
                                //     {part.assignmentItems.map((item, j) => {
                                //         itemCount = itemCount + 1;
                                //         return( 
                                //             <li key={`item${itemCount}`}>
                                //                 <Tippy content={item.name} placement="right" disabled={props.navigationOpen}>
                                //                 <AssignmentCard 
                                //                     data={item}       
                                //                     itemCount={itemCount}                              
                                //                     currentItem={props.currentItem === itemCount} 
                                //                     gotoItem={props.gotoItem}
                                //                     demo={props.data.demo}
                                //                     ref={ref}
                                //                 /></Tippy>
                                //             </li>
                                //         )
                                //     })}
                                // </ol>
                            // )
                        })}
                    </div>
                </nav>
                <button className="gr-btn icon-btn-24 scroll-btn" onClick={handleNextBtn} hidden={disableButtons}>
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#ascending-24"></use>
                    </svg>
                </button>
            </div>

        </aside>
    )
})

export default PLayerNavigation