
import OverviewPageHero from "./OverviewPageHero";
import AssignmentCard from '../shared/AssignmentCard';
import AssignmentGroup from "./AssignmentGroup";
import { useLocation, useNavigate } from "react-router-dom";
import OverallScore from "./OverallScore";

const OverviewPage = (props) => {
    const navigate = useNavigate();
    const {search} = useLocation();
    const assignment = new URLSearchParams(search).get('content');

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }


    let itemCount = -1;

    const countItemTypes = () => {
        let count = {reading:0, video:0,activity:0,question:0, other:0, total: 0, totalPoints:0, totalTime:0}

        props.data.assignmentParts.forEach(part => {
            part.assignmentItems.forEach(item => {
                count.total = count.total + 1
                count.totalPoints = count.totalPoints + item.totalPoints
                count.totalTime = count.totalTime + +item.time //in seconds
                // if (item.type === 'reading') {
                //     count.reading = count.reading + 1
                    
                // } else if (item.type === 'video') {
                //     count.video = count.video + 1
                // } else if (item.type === 'activity') {
                //     count.activity = count.activity + 1
                // } else if (item.type === 'question') {
                //     count.question = count.question + 1
                // } else {
                //     count.other = count.other + 1
                // }
            })
        })

        return count
    }
    const navigateToAssgn = () => {
        navigate(`/assignment${search}`)
    }
    const findPositionInItemList = (ind) => {
        console.log(props.itemsList)
        console.log(ind)
        let itemIndex = props.itemsList.findIndex(f => f.id === ind);

        return itemIndex
    }

    return(        
        <>
        <main className={`overview-main ${assignment}`}>
            <OverviewPageHero 
                assighmentType={'homework'} 
                assignmentChapter={props.data.chapter}
                assignmentName={props.data.name}
                dueDate={props.data.duedate}
                dueTime={props.data.duetime}
                overallScore={props.data.overallscore}
                totalPoints={countItemTypes().totalPoints}
                totalTime={countItemTypes().totalTime}
                itemCount={countItemTypes()}
                totalItems={countItemTypes().total}
                itemsCompleted={props.data.itemsCompleted}
                summary={props.data.summary}
                openPlayer={props.openPlayer}
                inView={props.inView}
                navigate={navigateToAssgn}
            />

            <section className="overview-body">
                <div className="wrapper card">
                    <div className="overview-body__left">
                        {props.data.assignmentParts.map((part, i) => {
                            itemCount = itemCount + 1;
                            let activeGroup = part.assignmentItems.filter(f => f.id === props.itemsList[props.currentItem].id)
                            console.log(activeGroup)
                            if (part.title) {
                                return (
                                    <AssignmentGroup data={part} key={`part${i}`} 
                                        open={activeGroup.length > 0 ? true : false}
                                        gotoItem={props.gotoItem} 
                                        findIndex={findPositionInItemList} 
                                        navigate={navigateToAssgn}
                                        grouped={props.grouped}
                                        page={'overview'}
                                        />
                                )
                            } else {
                                 return(
                                <ol className="assignment-group standalone" key={`part${i}`}>
                                    {part.assignmentItems.map((item, j) => {
                                        itemCount = itemCount + 1;
                                        
                                        return( 
                                            <li key={`item${j}`}>
                                                <AssignmentCard 
                                                    data={item} 
                                                    gotoItem={() => {props.gotoItem(findPositionInItemList(item.id));navigateToAssgn()}} 
                                                    itemCount={itemCount}
                                                    className="in-overview"
                                                />
                                            </li>
                                        )
                                    })}
                                </ol>
                            )
                            }
                           
                        })}
                    </div>
                    <div className="overview-body__right card">
                        <div className="card">
                    
                        <OverallScore
                            overallScore={props.data.overallscore}
                            totalPoints={countItemTypes().totalPoints}
                            totalItems={countItemTypes().total}
                            itemsCompleted={props.data.itemsCompleted}
                        />                    

                        <div className="assignment-objectives">
                            <h4>What you will learn:</h4>
                            <ol>
                                {props.data.learnings && props.data.learnings.map((item, i) => {
                                    return(
                                        <li key={`learning${i}`}>
                                            {item.item}
                                        </li>
                                    )
                                })}
                            </ol>
                        </div>
                        </div> 
                    </div>
                </div>
            </section>

            
        </main>
        {/* <button className="back-to-top" onClick={scrollToTop}>
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.62194 0.238387L6.62191 0.238431C6.63548 0.250546 6.64897 0.26335 6.66235 0.276859L11.7256 5.38635C12.0915 5.75549 12.0915 6.354 11.7256 6.72314C11.3598 7.09229 10.7667 7.09229 10.4009 6.72314L6 2.28205L1.59906 6.72314C1.23325 7.09229 0.640163 7.09229 0.274356 6.72314C-0.0914519 6.354 -0.0914519 5.75549 0.274356 5.38635L5.33765 0.276859C5.69027 -0.0789827 6.25409 -0.0918069 6.62194 0.238387Z" fill="#FEFEFE"/>
                </svg>
            </button> */}
        </>
    )
}

export default OverviewPage;