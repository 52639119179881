import React, { useState, useEffect} from "react";

const SpfCarousel = (props) => {
    const [active, setActive] = useState(0);

    const styles = {
        width: `calc(100% * ${props.data.length})`,
        transform: `${active > 0 ? `translateX(-${active * 100 / props.data.length}%)` : 'none'}`
    }

    const nextSlide = () => {
        const lengthOfCarousel = props.data.length;

        if (active === lengthOfCarousel - 1) {
            setActive(0)
        } else {
            setActive(active + 1)
        }
    }
    const prevSlide = () => {
        const lengthOfCarousel = props.data.length;

        if (active === 0) {
            setActive(lengthOfCarousel - 1)
        } else {
            setActive(active - 1)
        }
    }

    return (
        <div className={`carousel-container ${props.modifier ? props.modifier : ''}`}>
            <div className="sc-carousel__content-container">
                <button class="custom-arrow-container" aria-label="Previous" onClick={() => prevSlide()}>
                    <div class="custom-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="1 0 24 24">
                            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path>
                        </svg>
                    </div>
                </button>
                <div className="sc-carousel__pages-window">
                    <div className="sc-carousel__pages-container" style={styles}>
                        {props.data.map((slide, index) => {
                            return (
                                <div className="slide-container" key={"slide"+index}>
                                    <div className="slide-image">
                                        <img src={slide.image} alt="placeholder"/>
                                    </div>
                                    <div className="caption">
                                        <p dangerouslySetInnerHTML={{__html: slide.caption}}/>
                                    </div>
                                </div>
                            )
                        })}
                        
                    </div>
                </div>
                <button class="custom-arrow-container" aria-label="Next" onClick={() => nextSlide()}>
                    <div class="custom-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="-1 0 24 24">
                            <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"></path>
                        </svg>
                    </div>
                </button>
            </div>

            <div slot="dots" class="custom-dots">
                {props.data.map((slide, index) => {
                    return (
                        <div class="sc-carousel-dot__container">
                            <div class={`sc-carousel-dot__dot ${active === index ? 'sc-carousel-dot__dot_active' : ''}`}></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default SpfCarousel