import {forwardRef} from 'react';

const AssignmentCard = forwardRef((props, ref) => {
    const formatTime = (seconds) => {
        let minutes = Math.floor(seconds / 60);
        let extraSeconds = seconds % 60;
        extraSeconds = extraSeconds < 30 ? 0 : 1;


        return minutes + extraSeconds
    }
    return(        
        <div 
            className={`assignment-card ${props.className} ${props.data.type.replace(/\s/g, '-')} ${props.currentId === props.data.id ? 'selected' : ''}`} 
            ref={ref} 
            id={`card-${props.currentItem ? 'selected' : props.data.itemid}`} 
            role="link" 
            {...((!props.data.locked && !props.demo) && {onClick: () => props.gotoItem(props.itemCount)})}
            disabled={props.data.locked || props.demo}
            tabIndex='0'
        >
            
            <div className={`icon-holder ${props.data.type.replace(/\s/g, '-')}`}>
                {props.data.type === 'activity' ?
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#activity-24"></use>
                    </svg>                
                : props.data.type === 'reading' ?
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#synonym-24"></use>
                    </svg>                
                : props.data.type === 'question' ?
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#question-24"></use>
                    </svg>                
                : props.data.type === 'other' ?
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#other-24"></use>
                    </svg>                
                : props.data.type === 'example' ?
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#example-24"></use>
                    </svg>                
                : props.data.type === 'video' ?
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#video-24"></use>
                    </svg>                
                :null}
            </div>
            <p className="item-title">{props.data.name}</p>
            <ul className="item-meta-data">
                {/* <li className="type-chip">{props.data.type === 'interactive' ? 'Interactive Fig.' : props.data.type}</li> */}
                {/* <li className="item-time">{formatTime(props.data.time)} min</li> */}
                {props.data.totalPoints ?
                    <li className="item-points">{props.data.pointsEarned ? props.data.pointsEarned : '0'}/{props.data.totalPoints} {props.data.totalPoints === 1 ? "point" : "points"}</li>
                : null
                }                
            </ul>
            <div className={`item-status ${props.data.status}`}>
                {props.data.status === "completed" ?
                    // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //     <g>
                    //         <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" fill="none" stroke="#232323" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    //         <path d="M7.75 12L10.58 14.83L16.25 9.16998"  fill="none" stroke="#232323" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    //     </g>
                    // </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="status-complete">
                        <path d="M2 12C2 6.49219 6.45312 2 12 2C17.5078 2 22 6.49219 22 12C22 17.5469 17.5078 22 12 22C6.45312 22 2 17.5469 2 12ZM16.4922 10.2812C16.9219 9.85156 16.9219 9.1875 16.4922 8.75781C16.0625 8.32812 15.3984 8.32812 14.9688 8.75781L10.75 12.9766L8.99219 11.2578C8.5625 10.8281 7.89844 10.8281 7.46875 11.2578C7.03906 11.6875 7.03906 12.3516 7.46875 12.7812L9.96875 15.2812C10.3984 15.7109 11.0625 15.7109 11.4922 15.2812L16.4922 10.2812Z" />
                    </svg>
                : props.data.locked ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="status-locked">
                        <path d="M2.5 9C2.5 7.89543 3.39543 7 4.5 7H11.5C12.6046 7 13.5 7.89543 13.5 9V14C13.5 15.1046 12.6046 16 11.5 16H4.5C3.39543 16 2.5 15.1046 2.5 14V9Z" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 4C4.5 2.067 6.067 0.5 8 0.5C9.933 0.5 11.5 2.067 11.5 4V7H10.5V4C10.5 2.61929 9.38071 1.5 8 1.5C6.61929 1.5 5.5 2.61929 5.5 4V7H4.5V4Z"/>
                    </svg>
                :
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className="status-not-started">
                        <circle cx="11" cy="11" r="10" stroke="black" strokeWidth="2" strokeOpacity="0.54" strokeLinecap="round" strokeDasharray="4 5"/>
                    </svg>
                }
            </div>
        </div>
    )
})

export default AssignmentCard