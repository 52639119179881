import React from "react";

const SpfElementIframe = (props) => {

    return (
        <div className={`spf-rce-element spf-element-plugin`}>
            <div className="component-container">
                <div className={`${props.modifier ? props.modifier : ''}`} style={{height: props.height ? props.height : 300}}>
                    <iframe src={props.data.source} title={props.data.title} />
                </div>
            </div>
        </div>           
    )
}
export default SpfElementIframe