import { Avatar, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import PearsonLogo from '../assets/images/logo_pearson.svg';
import UserMenu from "../shared/UserMenu";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const PlayerHeader = (props) => {
    const [show,setShow] = useState(true)
    useEffect(() => {
    //    setShow(props.show)
    },[props.show])

    return (
        <header className={show ? "player-header show" : "player-header hide"}>
                <div className="player-header__left">
                    <button className={`hamburger ${props.mobileMenuOpen ? 'open' : ''}`} onClick={props.toggleMobileMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <div className="logo">
                        <img
                            src={PearsonLogo}
                            alt=''
                        />
                    </div>
                    {/* <span className="assignment-type"><span className='assignment-type__wide'>{props.data.type[0].desktop}</span><span className='assignment-type__mobile'>{props.data.type[0].mobile}</span>:</span> */}
                    <span className="assignment-title">{props.data.name}</span>
                </div>

                <div className="player-header__center">
                    <div className="progress-indicator">
                            <span className="progress-info inline-val">{props.data.overallscore}/{props.data.totalPoints} pts </span>
                            
                            <div className="progress">
                                <span style={{"width": Math.floor(100 * props.data.overallscore/props.data.totalPoints) + '%'}}></span>
                            </div>
                        </div>
                </div>

                <div className="player-header__right">
                   
                <IconButton>
					<HelpOutlineIcon sx={{ width: '24px', height: '24px' }} />
				</IconButton>
                <UserMenu/>
                    {/* <button className="gr-btn icon-btn-24" aria-label="Account">
                        <Avatar/>
                    </button> */}

                    <button className="gr-btn icon-btn-24" onClick={props.closePlayer} aria-label="Close assignment" disabled={props.data.demo}>
                        <svg focusable="false" className="icon-24" aria-hidden="true">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close-24"></use>
                        </svg>
                    </button>

                </div>
            </header>
    )
}
export default PlayerHeader